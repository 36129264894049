<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 pa-5 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >Seller List</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 md4 pa-5 v-for="(item, i) in shop" :key="i">
            <v-card>
              <v-layout wrap>
                <v-flex xs12>
                  <v-img :src="mediaUURL + item.profilePicture"></v-img>
                </v-flex>
                <v-flex xs12 pa-5>
                  <span style="font-family: poppinssemibold">{{
                    item.shopName
                  }}</span>
                </v-flex>
                <v-flex xs12 sm6 md4 pa-5 text-center>
                  <v-btn
                    block
                    color="green"
                    dark
                    :to="'/sellerEdit?id=' + item._id"
                    ><span style="color: white">Edit</span></v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md4 pa-5 text-center>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        block
                        color="red"
                        style="text-transform: none"
                        v-on="on"
                        v-bind="attrs"
                      >
                        <span style="color: white; font-size: 14px">
                          Delete
                        </span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Seller?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex xs12 sm6 md4 pa-5 text-center>
                  <v-btn
                    block
                    color="blue"
                    dark
                    :to="'/sellerView?id=' + item._id"
                    ><span style="color: white">View</span></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      shop: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/seller/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.shop = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/seller/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>